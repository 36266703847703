import {
  SubmitReset,
  SubmitResetPass,
} from "./Constants";
import axios from "axios";

export async function sendreset(jsonData) {
  try {
    const response = await axios.post(
      SubmitReset,
      jsonData
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function submitresetpass(jsonData) {
  try {
    const response = await axios.post(
      SubmitResetPass,
      jsonData
    );
    // console.log(response);
    return response;
  } catch (error) {
    return error.response;
  }
}
