import "./App.css";
import React, { useEffect } from "react";
import LoginPage from "./views/Login/Login";
// import TestPage from "./views/Login/tester";
import Dashboard from "./views/Login/Dashboard";
import MerchantStart from "./views/Login/MerchantStart";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  useEffect(() => {
    document.title = "Omari | Merchant Portal";

    const isDarkMode =
      window.matchMedia &&
      window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;

    if (isDarkMode) {
      console.log("Dark mode is enabled");
      document.documentElement.classList.add(
        "light-theme"
      );
    } else {
      console.log("Dark mode is not enabled");
    }
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<LoginPage />}
          />
          <Route
            path="/dashboard"
            element={<Dashboard />}
          />
          <Route
            path="/merchant"
            element={<MerchantStart />}
          />
          {/* <Route
            path="/test"
            element={<TestPage />}
          /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
