export const saveNOBEmail = (email) => {
  sessionStorage.setItem("email", email);
};

export const getNOBEmail = () => {
  return sessionStorage.getItem("email");
};

export const saveEmail = (email) => {
  sessionStorage.setItem("email", email);
};

export const getEmail = () => {
  return sessionStorage.getItem("email");
};

export const saveBusEmail = (email) => {
  sessionStorage.setItem("busemail", email);
};

export const getBusEmail = () => {
  return sessionStorage.getItem("busemail");
};

export const savePEmail = (pemail) => {
  sessionStorage.setItem("pemail", pemail);
};

export const getPEmail = () => {
  return sessionStorage.getItem("pemail");
};

export const savePFn = (pfn) => {
  sessionStorage.setItem("fn", pfn);
};

export const getPFn = () => {
  return sessionStorage.getItem("fn");
};

// New

export const saveOrgn = (orgn) => {
  sessionStorage.setItem("orgn", orgn);
};

export const getOrgn = () => {
  return sessionStorage.getItem("orgn");
};

// New

export const saveOrgtn = (orgtn) => {
  sessionStorage.setItem("orgtn", orgtn);
};

export const getOrgtn = () => {
  return sessionStorage.getItem("orgtn");
};
