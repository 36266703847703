import { LoginUp } from "./Constants";
import axios from "axios";

export async function sendlogin(jsonData) {
  try {
    const response = await axios.post(
      LoginUp,
      jsonData
    );
    // console.log(response);
    return response;
  } catch (error) {
    return error.response;
  }
}
