import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import afrogirl from "../../Assets/images/230135_3_Landing page_9.jpg";
import logo from "../../Assets/images/omarilogobgremove.png";
import { motion } from "framer-motion";
import JSZip from "jszip";
import "./Login.css";
import {
  signup,
  businesssignup,
  personsignup,
  veriOTP,
  reseOTP,
} from "../../Apis/SignupService";
import { sendlogin } from "../../Apis/LoginService";

import { Spin } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import {
  getBusEmail,
  getEmail,
  getNOBEmail,
  saveBusEmail,
  saveEmail,
  saveNOBEmail,
  saveOrgn,
  saveOrgtn,
  savePEmail,
  savePFn,
} from "../../Apis/Store";
import { useNavigate } from "react-router-dom";
import {
  sendreset,
  submitresetpass,
} from "../../Apis/ResetPasswordService";

const Signin = () => {
  const [isFileUploaded, setIsFileUploaded] =
    useState(false);
  const [isFileUploaded2, setIsFileUploaded2] =
    useState(false);
  const [isFileUploaded3, setIsFileUploaded3] =
    useState(false);
  const [isFileUploaded4, setIsFileUploaded4] =
    useState(false);
  const [isFileUploaded5, setIsFileUploaded5] =
    useState(false);
  const [isFileUploaded6, setIsFileUploaded6] =
    useState(false);
  const [isFileUploaded7, setIsFileUploaded7] =
    useState(false);
  const [isFileUploaded8, setIsFileUploaded8] =
    useState(false);
  const [gender, setGender] = useState("");
  const [businesstype, setBusinesstype] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [activeForm, setActiveForm] =
    useState("login");

  const [inputsuccess, setInputsuccess] =
    useState(false);

  const [busiType, setbusiType] = useState(false);

  const [inputError, setInputError] =
    useState(false);

  const [inputAddError, setInputAddError] =
    useState(false);

  const [fileinputError, setFileInputError] =
    useState(false);

  const [fileinputError2, setFileInputError2] =
    useState(false);

  const [fileinputError3, setFileInputError3] =
    useState(false);

  const [fileinputError4, setFileInputError4] =
    useState(false);

  const [fileinputError5, setFileInputError5] =
    useState(false);

  const [fileinputError6, setFileInputError6] =
    useState(false);

  const [fileinputError7, setFileInputError7] =
    useState(false);

  const [fileinputError8, setFileInputError8] =
    useState(false);

  const [inputErrorBS, setInputErrorBS] =
    useState(false);

  const [resendCount, setResendCount] =
    useState(0);

  const [file1, setFile1] = useState([]);
  const [file2, setFile2] = useState([]);

  const [file3, setFile3] = useState([]);
  const [file4, setFile4] = useState([]);
  const [file5, setFile5] = useState([]);
  const [file6, setFile6] = useState([]);
  const [file7, setFile7] = useState([]);
  const [file8, setFile8] = useState([]);

  const [formOTP, setFormOTP] = useState({
    OTP: "",
  });

  const [formLogin, setFormLogin] = useState({
    mobile: "",
    password: "",
  });

  const [formFields, setFormFields] = useState({
    FirstName: "",
    Surname: "",
    Phonenumber: "",
    Email: "",
    Idnum: "",
    Dob: "",
    Password: "",
    PasswordConfirmation: "",
  });

  const [businessFields, setBusinessFields] =
    useState({
      OrgName: "",
      OrgTrading: "",
      OrgType: "",
      Region: "",
      Contact: "",
      EmailOrg: "",
      IndustryOrg: "",
      PreferredName: "",
      NumofEmployees: "",
      AverageRev: "",
      BusinessAdd: "",
      BusinessAdd2: "",
      city: "",
      district: "",
      province: "",
      AN: "",
      AHN: "",
      Branch: "",
      BCode: "",
      Bank: "",
      TFTB: "",
      TDate: "",
      BusType: "",
    });

  const [personsFields, setPersonsFields] =
    useState({
      title: "",
      fn: "",
      ln: "",
      phon: "",
      email: "",
      dtyp: "",
      idnm: "",
      idco: "",
      rco: "",
      paddy: "",
      ctype: "",
    });

  const citiesAndTowns = [
    "Harare",
    "Bulawayo",
    "Chitungwiza",
    "Mutare",
    "Gweru",
    "Kwekwe",
    "Masvingo",
    "Victoria Falls",
    "Kadoma",
    "Chinhoyi",
    "Marondera",
    "Norton",
    "Rusape",
    "Zvishavane",
    "Beitbridge",
    "Bindura",
    "Gwanda",
    "Kariba",
    "Karoi",
    "Chipinge",
    "Hwange",
    "Shurugwi",
    "Redcliff",
    "Chegutu",
    "Gokwe",
  ];

  const provinces = [
    "Bulawayo",
    "Harare",
    "Manicaland",
    "Mashonaland Central",
    "Mashonaland East",
    "Mashonaland West",
    "Masvingo",
    "Matabeleland North",
    "Matabeleland South",
    "Midlands",
  ];

  const navigate = useNavigate();

  const errorRef = useRef(null); // Create a ref for the error message

  useEffect(() => {
    const authToken =
      localStorage.getItem("authToken");
    if (authToken) {
      if (!getNOBEmail()) {
        navigate("/Dashboard");
      } else {
        setActiveForm("form2");
      }
    }
  }, [navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleBusinessChange = (event) => {
    const { name, value } = event.target;
    setBusinessFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleBusinessTypeChange = (event) => {
    const { name, value } = event.target;
    setBusinessFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleBusinessTypeChanger = (event) => {
    const { name, value } = event.target;
    setBusinesstype(value);
    setbusiType(value);
    setBusinessFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handlePersonChange = (event) => {
    const { name, value } = event.target;
    setPersonsFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleInputChangeOTP = (event) => {
    const { name, value } = event.target;
    setFormOTP((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleInputChangeLogin = (event) => {
    const { name, value } = event.target;
    setFormLogin((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const userData = {
    firstname: formFields.FirstName,
    surname: formFields.Surname,
    phonenum: formFields.Phonenumber,
    email: formFields.Email,
    idnum: formFields.Idnum,
    dob: formFields.Dob,
    gender: gender,
    acc_pass: formFields.Password,
  };

  const validateFormFieldsReset = () => {
    if (formFields.Password.length < 11) {
      setLoading(false);
      setInputError(
        "Password should have 11 characters"
      );
      scrollToError();
      return false;
    }

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d.*\d.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{11}$/;
    if (
      !passwordRegex.test(formFields.Password)
    ) {
      setLoading(false);
      setInputError(
        "Password must contain at least one uppercase letter, one special character, and three numbers."
      );
      scrollToError();
      return false;
    }

    if (
      formFields.PasswordConfirmation !==
      formFields.Password
    ) {
      setLoading(false);
      setInputError("Passwords must match");
      scrollToError();
      return false;
    }

    return true;
  };

  const validateFormFields = () => {
    if (formFields.Phonenumber.length < 12) {
      setLoading(false);
      setInputError(
        "Phone number should have 12 characters e.g. 263777777777"
      );
      scrollToError();
      return false;
    }

    const phoneRegex = /^263(78|77|71|73)\d{7}$/; // Matches 263 + allowed prefixes + 7 digits
    if (
      !phoneRegex.test(formFields.Phonenumber)
    ) {
      setLoading(false);
      setInputError(
        "Phone number should start with 263 followed by either 78, 77, 71, or 73"
      );
      scrollToError();
      return false;
    }

    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(formFields.FirstName)) {
      setLoading(false);
      setInputError(
        "First name should contain only letters and spaces."
      );
      scrollToError();
      return false;
    }

    if (formFields.FirstName.length < 2) {
      setLoading(false);
      setInputError(
        "First name should be at least 2 characters long."
      );
      scrollToError();
      return false;
    }

    if (!nameRegex.test(formFields.Surname)) {
      setLoading(false);
      setInputError(
        "Surname should contain only letters and spaces."
      );
      scrollToError();
      return false;
    }

    if (formFields.Surname.length < 2) {
      setLoading(false);
      setInputError(
        "Surname should be at least 2 characters long."
      );
      scrollToError();
      return false;
    }

    const idNumRegex = /^\d{9}[A-Za-z]\d{2}$/; // Matches 9 digits, 1 letter, and 2 digits
    if (!idNumRegex.test(formFields.Idnum)) {
      setLoading(false);
      setInputError(
        "ID number should be in the correct format and correct characters"
      );
      scrollToError();
      return false;
    }

    if (formFields.Idnum.length !== 12) {
      setLoading(false);
      setInputError(
        "ID number should have 12 characters e.g. 123456789E90"
      );
      scrollToError();
      return false;
    }

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d.*\d.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{11}$/;
    if (
      !passwordRegex.test(formFields.Password)
    ) {
      setLoading(false);
      setInputError(
        "Password must be 11 characters long, containing at least one uppercase letter, one special character, and three numbers"
      );
      scrollToError();
      return false;
    }

    if (
      formFields.PasswordConfirmation !==
      formFields.Password
    ) {
      setLoading(false);
      setInputError("Passwords must match");
      scrollToError();
      return false;
    }

    return true;
  };

  const validatePerson = () => {
    if (personsFields.fn.length < 2) {
      setLoading(false);
      setInputError(
        "First name should be at least 2 characters long."
      );
      scrollToError();
      return false;
    }

    const idNumRegex = /^\d{9}[A-Za-z]\d{2}$/; // Matches 9 digits, 1 letter, and 2 digits
    if (!idNumRegex.test(personsFields.idnm)) {
      setLoading(false);
      setInputError(
        "ID number should be in the correct format and correct characters"
      );
      scrollToError();
      return false;
    }

    if (personsFields.ln.length < 2) {
      setLoading(false);
      setInputError(
        "Surname should be at least 2 characters long."
      );
      scrollToError();
      return false;
    }

    const nameRegex = /^[A-Za-z\s]+$/;

    if (!nameRegex.test(personsFields.fn)) {
      setLoading(false);
      setInputError(
        "First Name should contain only letters and spaces."
      );
      scrollToError();
      return false;
    }

    if (!nameRegex.test(personsFields.ln)) {
      setLoading(false);
      setInputError(
        "Surname should contain only letters and spaces."
      );
      scrollToError();
      return false;
    }

    if (personsFields.phon.length < 12) {
      setLoading(false);
      setInputError(
        "Phone number should have 12 characters e.g. 263777777777"
      );

      scrollToError();
      return false;
    }

    if (personsFields.idnm.length !== 12) {
      setLoading(false);
      setInputError(
        "ID number should have 12 characters e.g. 123456789E90"
      );
      scrollToError();
      return false;
    }

    return true;
  };

  const otpData = {
    code: formOTP.OTP,
    phonenumber: formFields.Phonenumber,
  };

  const otpDataReset = {
    code: formOTP.OTP,
    phonenumber: formLogin.mobile,
  };

  const resendData = {
    phonenum: formFields.Phonenumber,
  };

  const handleResendOTP = async (event) => {
    setInputError("");
    event.preventDefault();
    setLoading(true);
    if (resendCount >= 2) {
      setInputsuccess("");
      setInputError(
        "Maximum number of resend OTP attempts reached."
      );

      setLoading(false);
      return;
    }

    setResendCount(resendCount + 1);
    try {
      const response = await reseOTP(resendData);
      if (
        response.status === 400 ||
        response.status === 401 ||
        response.status === 500
      ) {
        setInputsuccess("");
        setInputError(response.error.error);
        setLoading(false);
      } else if (response.status === 200) {
        setInputError("");
        setInputsuccess(response.data.message);
        setLoading(false);
      } else {
        setLoading(false);
        setInputsuccess("");
        setInputError(
          "Failing to connect try, again later"
        );
      }
    } catch (e) {
      setLoading(false);
      setInputsuccess("");
      setInputError(
        "Failing to connect try, again later"
      );
    }
  };

  const signupbttn = async () => {
    setInputError("");
    setActiveForm("form1");
  };

  const resetbutton = async () => {
    setInputError("");
    formLogin.mobile = "";
    setActiveForm("resetpassword");
  };

  const handleResetCodeSubmit = async (event) => {
    setInputError("");
    event.preventDefault();
    setLoading(true);
    try {
      const response =
        await veriOTP(otpDataReset);
      // console.log(otpDataReset);
      if (response.status === 400) {
        setInputError(response.error.error);
        setLoading(false);
      } else if (response.status === 401) {
        setInputError(
          "Invalid or expired Reset Code."
        );
        setLoading(false);
      } else if (response.status === 200) {
        setInputError("");
        setActiveForm("subresetpassword");

        setLoading(false);
      } else {
        setLoading(false);
        setInputError(
          "Failing to connect try, again later"
        );
      }
    } catch (e) {
      setLoading(false);
      setInputError(
        "Failing to connect try, again later"
      );
    }
  };

  const handleOTPSubmit = async (event) => {
    setInputError("");
    event.preventDefault();
    setLoading(true);
    try {
      const response = await veriOTP(otpData);
      if (
        response.status === 400 ||
        response.status === 401
      ) {
        setInputError(response.error.error);
        setLoading(false);
      } else if (response.status === 200) {
        saveEmail(formFields.Email);
        setInputError("");
        setActiveForm("info");
        setLoading(false);
      } else {
        setLoading(false);
        setInputError(
          "Failing to connect try, again later"
        );
      }
    } catch (e) {
      setLoading(false);
      setInputError(
        "Failing to connect try, again later"
      );
    }
  };

  const subpassjson = {
    username: formLogin.mobile,
    password: formFields.Password,
    code: formOTP.OTP,
  };

  const handleResetPasswordSubmit = async (
    event
  ) => {
    event.preventDefault();
    if (validateFormFieldsReset()) {
      setLoading(true);
      setInputError("");
      try {
        const response =
          await submitresetpass(subpassjson);
        // console.log(response);
        if (response.status == 200) {
          setActiveForm("login");
          formLogin.mobile = "";
          formFields.Password = "";
          formFields.PasswordConfirmation = "";
          formOTP.OTP = "";
          setLoading(false);
        } else if (response.status == 400) {
          setInputError("Sorry, try again later");
          setLoading(false);
        } else if (response.status == 500) {
          setInputError(
            "Failing to connect, Please try again later"
          );
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        // console.log(e);
        setInputError(
          "Failing to connect, Please try again later"
        );
      }
    }
  };

  const handleSignSubmit = async (event) => {
    setInputError("");
    event.preventDefault();
    setLoading(true);
    if (validateFormFields()) {
      try {
        const response = await signup(userData);
        if (response.status === 400) {
          setInputError(response.error.error);
          setLoading(false);
        } else if (response.status === 201) {
          saveEmail(formFields.Email);
          setInputError("");
          setActiveForm("activationcode");
          setLoading(false);
        } else {
          setLoading(false);
          setInputError(
            "Failing to connect try, again later"
          );
        }
      } catch (e) {
        // console.log(e);
        setLoading(false);
        setInputError(
          "Failing to connect try, again later"
        );
      }
    }
  };

  const handleBusinessSubmit = async (event) => {
    event.preventDefault();

    const nameRegex = /^[A-Za-z\s]+$/;

    const phoneRegex =
      /^263(2|78|77|71|73)\d{7}$/;
    if (!nameRegex.test(businessFields.OrgType)) {
      setLoading(false);
      setInputErrorBS(
        "Please enter a correct organisation type"
      );
      scrollToError();
    } else if (
      !nameRegex.test(businessFields.Region)
    ) {
      setLoading(false);
      setInputErrorBS(
        "Please enter a correct region"
      );
      scrollToError();
    } else if (
      !phoneRegex.test(businessFields.Contact)
    ) {
      setLoading(false);
      setInputErrorBS(
        "Phone number should start with 263 and 12 or 11 characters long."
      );
      scrollToError(); // Scroll to error message when validation fails
    } else if (
      !nameRegex.test(businessFields.IndustryOrg)
    ) {
      setLoading(false);
      setInputErrorBS(
        "Please enter a correct industry"
      );
      scrollToError();
    } else {
      setInputErrorBS("");
      setActiveForm("form3");
    }
  };

  const handleBusinessDocs = async (event) => {
    event.preventDefault();
    setInputError("");
    setActiveForm("form4");
  };

  const handleBusinessDetails = async (event) => {
    event.preventDefault();
    setInputAddError("");
    const addressRegex = /^[a-zA-Z0-9\s,.-]+$/; // Allows letters, numbers, spaces, commas, periods, and dashes
    if (
      !businessFields.BusinessAdd.match(
        addressRegex
      ) ||
      !businessFields.BusinessAdd2.match(
        addressRegex
      )
    ) {
      setInputAddError(
        "Please enter a valid Business Addresses."
      );
    } else {
      setInputAddError("Valid Business address");
      setInputAddError("");
      setActiveForm("docupload");
    }
  };

  const handleBankingDetails = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("file3", file3[0]);
    formData.append("file4", file4[0]);
    formData.append("file5", file5[0]);
    formData.append("file6", file6[0]);
    formData.append("file7", file7[0]);
    formData.append("file8", file8[0]);
    formData.append("PEmail", getEmail());
    formData.append(
      "OrgN",
      businessFields.OrgName
    );
    formData.append(
      "OrgTN",
      businessFields.OrgTrading
    );
    formData.append(
      "OrgT",
      businessFields.OrgType
    );
    formData.append(
      "OrgR",
      businessFields.Region
    );
    formData.append(
      "OrgE",
      businessFields.EmailOrg
    );
    formData.append(
      "OrgI",
      businessFields.IndustryOrg
    );
    formData.append(
      "PreferredNA",
      businessFields.PreferredName
    );
    formData.append(
      "NumOfE",
      businessFields.NumofEmployees
    );
    formData.append(
      "AverageR",
      businessFields.AverageRev
    );
    formData.append(
      "BusA",
      businessFields.BusinessAdd
    );
    formData.append(
      "BusA2",
      businessFields.BusinessAdd2
    );
    formData.append("City", businessFields.city);
    formData.append(
      "District",
      businessFields.district
    );
    formData.append(
      "Province",
      businessFields.province
    );
    formData.append("AN", businessFields.AN);
    formData.append("AHN", businessFields.AHN);
    formData.append("BusinessType", busiType);
    formData.append(
      "BCode",
      businessFields.BCode
    );
    formData.append("Bank", businessFields.Bank);
    formData.append("TFTB", businessFields.TFTB);
    formData.append(
      "TDate",
      businessFields.TDate
    );
    try {
      console.log(busiType);
      const response =
        await businesssignup(formData);
      if (response.status === 400) {
        setInputError(response.error.error);
        setLoading(false);
      } else if (response.status === 201) {
        saveBusEmail(businessFields.EmailOrg);
        setInputError("");
        setActiveForm("form5");
        setLoading(false);
      } else {
        setLoading(false);
        setInputError(
          "Failing to connect, Please try again later"
        );
      }
    } catch (e) {
      setLoading(false);
      setInputError(
        "Failing to connect, Please try again later"
      );
    }
  };

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    const fileType = file.type;

    setFileInputError("");
    if (
      fileType === "application/pdf" ||
      fileType === "image/jpeg" ||
      fileType === "image/jpg" ||
      fileType === "image/png"
    ) {
      setFile1((prevFile1) => {
        const updatedFiles = [...prevFile1];
        updatedFiles[index] = file;
        return updatedFiles;
      });
      setIsFileUploaded(true); // Set success state
    } else {
      setFileInputError(
        "Please select a JPG, PNG or PDF file."
      );
      event.target.value = null;
      setIsFileUploaded(false); // Set success state
    }
  };

  const handleFileChange2 = (event, index) => {
    const file = event.target.files[0];
    const fileType = file.type;

    setFileInputError2("");
    if (
      fileType === "application/pdf" ||
      fileType === "image/jpeg" ||
      fileType === "image/jpg" ||
      fileType === "image/png"
    ) {
      setFile2((prevFile2) => {
        const updatedFiles = [...prevFile2];
        updatedFiles[index] = file;
        return updatedFiles;
      });
      setIsFileUploaded2(true); // Set success state
    } else {
      setFileInputError2(
        "Please select a JPG, PNG or PDF file."
      );
      event.target.value = null;
      setIsFileUploaded2(false); // Set success state
    }
  };

  const handleFileChange3 = (event, index) => {
    const file = event.target.files[0];
    const fileType = file.type;

    setFileInputError3("");
    if (
      fileType === "application/pdf" ||
      fileType === "image/jpeg" ||
      fileType === "image/jpg" ||
      fileType === "image/png"
    ) {
      setFile3((prevFile3) => {
        const updatedFiles = [...prevFile3];
        updatedFiles[index] = file;
        return updatedFiles;
      });
      setIsFileUploaded3(true); // Set success state
    } else {
      setFileInputError3(
        "Please select a JPG, PNG or PDF file."
      );
      event.target.value = null;
      setIsFileUploaded3(false); // Set success state
    }
  };

  const handleFileChange4 = (event, index) => {
    const file = event.target.files[0];
    const fileType = file.type;

    setFileInputError4("");
    if (
      fileType === "application/pdf" ||
      fileType === "image/jpeg" ||
      fileType === "image/jpg" ||
      fileType === "image/png"
    ) {
      setFile4((prevFile4) => {
        const updatedFiles = [...prevFile4];
        updatedFiles[index] = file;
        return updatedFiles;
      });
      setIsFileUploaded4(true); // Set success state
    } else {
      setFileInputError4(
        "Please select a JPG, PNG or PDF file."
      );
      event.target.value = null;
      setIsFileUploaded4(false); // Set success state
    }
  };

  const handleFileChange5 = (event, index) => {
    const file = event.target.files[0];
    const fileType = file.type;

    setFileInputError5("");
    if (
      fileType === "application/pdf" ||
      fileType === "image/jpeg" ||
      fileType === "image/jpg" ||
      fileType === "image/png"
    ) {
      setFile5((prevFile5) => {
        const updatedFiles = [...prevFile5];
        updatedFiles[index] = file;
        return updatedFiles;
      });
      setIsFileUploaded5(true); // Set success state
    } else {
      setFileInputError5(
        "Please select a JPG, PNG or PDF file."
      );
      event.target.value = null;
      setIsFileUploaded5(false); // Set success state
    }
  };

  const handleFileChange6 = (event, index) => {
    const file = event.target.files[0];
    const fileType = file.type;

    setFileInputError6("");
    if (
      fileType === "application/pdf" ||
      fileType === "image/jpeg" ||
      fileType === "image/jpg" ||
      fileType === "image/png"
    ) {
      setFile6((prevFile6) => {
        const updatedFiles = [...prevFile6];
        updatedFiles[index] = file;
        return updatedFiles;
      });
      setIsFileUploaded6(true); // Set success state
    } else {
      setFileInputError6(
        "Please select a JPG, PNG or PDF file."
      );
      event.target.value = null;
      setIsFileUploaded6(false); // Set success state
    }
  };

  const handleFileChange7 = (event, index) => {
    const file = event.target.files[0];
    const fileType = file.type;

    setFileInputError7("");
    if (
      fileType === "application/pdf" ||
      fileType === "image/jpeg" ||
      fileType === "image/jpg" ||
      fileType === "image/png"
    ) {
      setFile7((prevFile7) => {
        const updatedFiles = [...prevFile7];
        updatedFiles[index] = file;
        return updatedFiles;
      });
      setIsFileUploaded7(true); // Set success state
    } else {
      setFileInputError7(
        "Please select a JPG, PNG or PDF file."
      );
      event.target.value = null;
      setIsFileUploaded7(false); // Set success state
    }
  };

  const handleFileChange8 = (event, index) => {
    const file = event.target.files[0];
    const fileType = file?.type;

    setFileInputError8("");
    if (
      fileType === "application/pdf" ||
      fileType === "image/jpeg" ||
      fileType === "image/jpg" ||
      fileType === "image/png"
    ) {
      setFile8((prevFile8) => {
        const updatedFiles = [...prevFile8];
        updatedFiles[index] = file;
        return updatedFiles;
      });
      setIsFileUploaded8(true); // Set success state
    } else {
      setFileInputError8(
        "Please select a JPG, PNG or PDF file."
      );
      event.target.value = null;
      setIsFileUploaded8(false); // Reset success state
    }
  };

  const loginjson = {
    username: formLogin.mobile,
    password: formLogin.password,
  };

  const resetjson = {
    username: formLogin.mobile,
  };

  const handleResetpass = async (event) => {
    setLoading(true);
    event.preventDefault();
    setInputError("");
    if (formLogin.mobile.length !== 12) {
      setInputError(
        "Please use 263777777777 format"
      );
      setLoading(false);
    } else {
      setInputError("");
      try {
        const response =
          await sendreset(resetjson);
        // console.log(response);
        if (response.status == 200) {
          setLoading(false);
          setActiveForm("setpassword");
        } else if (response.status == 401) {
          setInputError(
            "Sorry, the mobile number wasn't found, Please check it try again."
          );
          formLogin.password = "";
          setLoading(false);
        } else if (response.status == 500) {
          setInputError(
            "Failing to connect, Please try again later"
          );
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.log(e);
        setInputError(
          "Failing to connect, Please try again later"
        );
      }
    }
  };

  const handlelogin = async (event) => {
    setLoading(true);
    event.preventDefault();
    setInputError("");
    try {
      const response = await sendlogin(loginjson);
      if (response.status == 200) {
        localStorage.setItem(
          "authToken",
          response.data.token
        );
        savePEmail(response.data.em);
        savePFn(response.data.fn);
        saveOrgn(response.data.orgn);
        saveOrgtn(response.data.orgt);
        setLoading(false);
        navigate("/Dashboard");
      } else if (response.status == 401) {
        setInputError(
          "Incorrect Phone number or password"
        );
        formLogin.password = "";
        setLoading(false);
      } else if (response.status == 500) {
        setInputError(
          "Failing to connect, Please try again later"
        );
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      setInputError(
        "Failing to connect, Please try again later"
      );
    }
  };

  const handlePersonDetails = async (event) => {
    event.preventDefault();
    setLoading(true);
    setInputError("");
    if (validatePerson()) {
      const formData = new FormData();
      formData.append("file1", file1[0]);
      formData.append("file2", file2[0]);
      formData.append(
        "title",
        personsFields.title
      );
      formData.append("fn", personsFields.fn);
      formData.append("ln", personsFields.ln);
      formData.append("phon", personsFields.phon);
      formData.append(
        "email",
        personsFields.email
      );
      formData.append("orgEmail", getBusEmail());
      formData.append("dtyp", personsFields.dtyp);
      formData.append("idnm", personsFields.idnm);
      formData.append(
        "paddy",
        personsFields.paddy
      );

      try {
        const response =
          await personsignup(formData);
        if (response.status === 400) {
          setInputError(response.error.error);
          setLoading(false);
        } else if (response.status === 201) {
          saveNOBEmail("");
          setLoading(false);
          if (getNOBEmail() === "") {
            navigate("/Dashboard");
          } else {
            window.location.reload();
          }
        } else {
          setLoading(false);
          setInputError(
            "Failing to connect, Please try again later"
          );
        }
      } catch (e) {
        setLoading(false);
        setInputError(
          "Failing to connect, Please try again later"
        );
      }
    }
  };

  const downTandC = () => {
    let fileUrl = "";
    fileUrl =
      process.env.PUBLIC_URL +
      "/O'mari Terms and Conditions Final.pdf";

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = fileUrl;
    a.download =
      "O'mari Terms and Conditions Final.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downPP = () => {
    let fileUrl = "";
    fileUrl =
      process.env.PUBLIC_URL +
      "/O'mari Privacy Policy Final version.pdf";

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = fileUrl;
    a.download =
      "O'mari Privacy Policy Final version.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadPDF = (event) => {
    event.preventDefault();
    let fileUrl = "";
    if (busiType === "Merchant") {
      fileUrl =
        process.env.PUBLIC_URL +
        "/MerchantAgreement1.pdf";

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileUrl;
      a.download = "MerchantAgreement1.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // window.location.reload();
    }

    if (busiType === "Agent") {
      fileUrl =
        process.env.PUBLIC_URL +
        "/AgentForms.pdf";

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileUrl;
      a.download = "AgentForms.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // window.location.reload();
    }

    if (busiType === "Bulk Payer") {
      const fileUrls = [
        {
          url:
            process.env.PUBLIC_URL +
            "/Bulk Payer Application.pdf",
          name: "Bulk Payer Application.pdf",
        },
        {
          url:
            process.env.PUBLIC_URL +
            "/payment agreement.pdf",
          name: "payment agreement.pdf",
        },
      ];

      const zip = new JSZip();

      const fetchFile = async (file) => {
        const response = await fetch(file.url);
        const blob = await response.blob();
        zip.file(file.name, blob);
      };

      const downloadZip = async () => {
        await Promise.all(
          fileUrls.map(fetchFile)
        );
        const content = await zip.generateAsync({
          type: "blob",
        });
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = URL.createObjectURL(content);
        a.download = "Bulk payer agreements.zip";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // window.location.reload();
      };

      downloadZip();
    }
  };

  const scrollToError = () => {
    if (errorRef.current) {
      errorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
        <section
          style={{
            backgroundColor: "#53BF4C",
          }}
          className="relative flex h-32 items-end lg:h-full xl:h-full lg:col-span-5 xl:col-span-6"
        >
          <img
            alt=""
            src={afrogirl}
            className="absolute inset-0 h-full w-full object-cover opacity"
          />

          <div className="hidden lg:relative lg:block lg:p-12">
            <button className="block text-white">
              <span className="sr-only">
                Home
              </span>
              <svg
                className="h-8 sm:h-10"
                viewBox="0 0 28 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="14"
                  cy="12"
                  r="10"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
              <span className="smt">
                {" "}
                O&apos;mari
              </span>
            </button>

            <h2 className="mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
              Welcome to O&apos;mari Business
              Portal
            </h2>

            <p className="mt-4 leading-relaxed text-white/90">
              Creating a better everyday life
              today and tomorrow
            </p>
          </div>
        </section>
        {loading && (
          <Spin size="large" fullscreen />
        )}
        <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
          <div className="max-w-xl lg:max-w-3xl">
            <div className="relative -mt-16 block lg:hidden">
              <br />
              <br />
              <button
                className="block"
                style={{
                  color: "rgb(83, 191, 76)",
                }}
              >
                <span className="sr-only">
                  Home
                </span>
                <svg
                  className="h-8 sm:h-10 ml-2 sm:ml-1"
                  viewBox="0 0 28 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="14"
                    cy="12"
                    r="10"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="3"
                  />
                </svg>
                <b> O&apos;mari</b>
              </button>
              <h2 className="mt-6 text-2xl font-bold text-black sm:text-3xl md:text-4xl">
                Welcome to O&apos;mari Business
                Portal
              </h2>

              <p className="mt-4 leading-relaxed text-black/90">
                Creating a better everyday life
                today and tomorrow by walking with
                you step by step on your life
                journey.
              </p>
            </div>

            <motion.div
            // initial={{
            //   x:
            //     activeForm === "form1"
            //       ? 0
            //       : "-100%",
            // }}
            // animate={{
            //   x: activeForm === "form1" ? 5 : 0,
            // }}
            // transition={{ duration: 0.1 }}
            >
              <div className="form-container">
                {activeForm === "login" && (
                  <motion.div>
                    {" "}
                    <br />
                    <img
                      style={{
                        maxWidth: "100%",
                        height: 150,
                      }}
                      src={logo}
                      alt="logo"
                    />
                    <hr />
                    <h3
                      style={{ color: "#53BF4C" }}
                      className="mt-6 text-4xl font-bold text-black sm:text-1xl md:text-1xl"
                    >
                      O&apos;mari Business portal
                      login
                    </h3>
                    <h2
                      style={{
                        color: "#53BF4C",
                        fontWeight: "bolder",
                      }}
                    >
                      Merchants / Agents / Bulk
                      Payers
                    </h2>
                    <p>Enter your credentials</p>
                    {inputError && (
                      <p style={{ color: "red" }}>
                        {inputError}
                      </p>
                    )}
                    <form
                      onSubmit={handlelogin}
                      className="grid grid-cols-6 gap-10"
                    >
                      <div className="mt-7 col-span-6 sm:col-span-6">
                        <label
                          htmlFor="FirstName"
                          className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                        >
                          Mobile Number
                        </label>

                        <input
                          type="number"
                          placeholder="26377777777"
                          name="mobile"
                          required
                          onChange={
                            handleInputChangeLogin
                          }
                          value={formLogin.mobile}
                          className="p-2 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                        />
                      </div>
                      <div className="mt-4 col-span-6 sm:col-span-6">
                        <label
                          htmlFor="LastName"
                          className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                        >
                          Password
                        </label>

                        <input
                          type="password"
                          name="password"
                          required
                          onChange={
                            handleInputChangeLogin
                          }
                          value={
                            formLogin.password
                          }
                          className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                        />
                      </div>
                      <div className="col-span-76 sm:flex sm:items-center sm:gap-4">
                        <button
                          type="submit"
                          className="inline-block shrink-0 rounded-md border border-green-600 bg-green-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                        >
                          Login
                        </button>{" "}
                      </div>
                    </form>
                    <br />
                    <span>
                      {" "}
                      Forgot Password?
                    </span>{" "}
                    <button
                      onClick={() =>
                        resetbutton()
                      }
                      style={{
                        color: "#53BF4C",
                      }}
                    >
                      {" "}
                      <u>Reset Here</u>
                    </button>
                    <br />
                    <span>
                      {" "}
                      Dont have an account ?
                    </span>
                    <button
                      onClick={() => signupbttn()}
                      style={{
                        color: "#53BF4C",
                      }}
                    >
                      {" "}
                      <u>Sign up</u>
                    </button>
                  </motion.div>
                )}

                {activeForm ===
                  "resetpassword" && (
                  <motion.div>
                    {" "}
                    <br />
                    <img
                      style={{
                        maxWidth: "100%",
                        height: 150,
                      }}
                      src={logo}
                      alt="logo"
                    />
                    <hr />
                    <h3
                      style={{ color: "#53BF4C" }}
                      className="mt-6 text-4xl font-bold text-black sm:text-1xl md:text-1xl"
                    >
                      O&apos;mari Business portals
                    </h3>
                    <h2
                      style={{
                        color: "#53BF4C",
                        fontWeight: "bolder",
                      }}
                    >
                      Merchants / Agents / Bulk
                      Payers
                    </h2>
                    <p>Reset Password</p>
                    {inputError && (
                      <p style={{ color: "red" }}>
                        {inputError}
                      </p>
                    )}
                    <form
                      onSubmit={handleResetpass}
                      className="grid grid-cols-6 gap-10"
                    >
                      <div className="mt-7 col-span-6 sm:col-span-6">
                        <label
                          htmlFor="FirstName"
                          className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                        >
                          Enter Mobile Number
                        </label>

                        <input
                          type="number"
                          placeholder="26377777777"
                          name="mobile"
                          required
                          onChange={
                            handleInputChangeLogin
                          }
                          value={formLogin.mobile}
                          className="p-2 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                        />
                      </div>
                      <div className="col-span-76 sm:flex sm:items-center sm:gap-4">
                        <button
                          type="submit"
                          className="inline-block shrink-0 rounded-md border border-green-600 bg-green-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                        >
                          Submit
                        </button>{" "}
                      </div>
                    </form>
                    <br />
                    <span>
                      {" "}
                      Don&apos;t need to reset ?
                    </span>{" "}
                    <button
                      onClick={() =>
                        setActiveForm("login")
                      }
                      style={{
                        color: "#53BF4C",
                      }}
                    >
                      {" "}
                      <u>Login</u>
                    </button>
                    <br />
                    <span>
                      {" "}
                      Dont have an account ?
                    </span>{" "}
                    <button
                      onClick={() => signupbttn()}
                      style={{
                        color: "#53BF4C",
                      }}
                    >
                      {" "}
                      <u>Sign up</u>
                    </button>
                  </motion.div>
                )}

                {activeForm === "setpassword" && (
                  <motion.div>
                    {" "}
                    <br />
                    <img
                      style={{
                        maxWidth: "100%",
                        height: 150,
                      }}
                      src={logo}
                      alt="logo"
                    />
                    <hr />
                    <h3
                      style={{ color: "#53BF4C" }}
                      className="mt-6 text-4xl font-bold text-black sm:text-1xl md:text-1xl"
                    >
                      O&apos;mari Business portal
                    </h3>
                    <h2
                      style={{
                        color: "#53BF4C",
                        fontWeight: "bolder",
                      }}
                    >
                      Merchants / Agents / Bulk
                      Payers
                    </h2>
                    <p>Submit Reset Password</p>
                    {inputError && (
                      <p style={{ color: "red" }}>
                        {inputError}
                      </p>
                    )}
                    <form
                      onSubmit={
                        handleResetCodeSubmit
                      }
                      className="grid grid-cols-6 gap-10"
                    >
                      <div className="mt-7 col-span-6 sm:col-span-6">
                        <label
                          htmlFor="FirstName"
                          className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                        >
                          Enter Reset Code
                        </label>
                        <input
                          type="number"
                          placeholder="Reset Code"
                          name="OTP"
                          required
                          onChange={
                            handleInputChangeOTP
                          }
                          value={formOTP.OTP}
                          className="p-2 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                        />
                      </div>
                      <div className="col-span-76 sm:flex sm:items-center sm:gap-4">
                        <button
                          type="submit"
                          className="inline-block shrink-0 rounded-md border border-green-600 bg-green-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                        >
                          Submit
                        </button>{" "}
                      </div>
                    </form>
                    <br />
                    <span>
                      {" "}
                      Did receive Reset code ?
                    </span>
                    <a
                      href="javascript:void(0)"
                      onClick={handleResendOTP}
                      style={{
                        color: "#53BF4C",
                      }}
                    >
                      {" "}
                      <u>Resend Reset Code</u>
                    </a>
                    <br />
                    <span>
                      {" "}
                      Don&apos;t need to reset ?
                    </span>{" "}
                    <button
                      onClick={() =>
                        setActiveForm("login")
                      }
                      style={{
                        color: "#53BF4C",
                      }}
                    >
                      {" "}
                      <u>Login</u>
                    </button>
                  </motion.div>
                )}

                {activeForm ===
                  "subresetpassword" && (
                  <motion.div>
                    {" "}
                    <br />
                    <img
                      style={{
                        maxWidth: "100%",
                        height: 150,
                      }}
                      src={logo}
                      alt="logo"
                    />
                    <hr />
                    <h3
                      style={{ color: "#53BF4C" }}
                      className="mt-6 text-4xl font-bold text-black sm:text-1xl md:text-1xl"
                    >
                      O&apos;mari Business portal
                    </h3>
                    <h2
                      style={{
                        color: "#53BF4C",
                        fontWeight: "bolder",
                      }}
                    >
                      Merchants / Agents / Bulk
                      Payers
                    </h2>
                    <p>Reset Your Password</p>
                    {inputError && (
                      <p style={{ color: "red" }}>
                        {inputError}
                      </p>
                    )}
                    <form
                      onSubmit={
                        handleResetPasswordSubmit
                      }
                      className="grid grid-cols-6 gap-10"
                    >
                      <div className="mt-7 col-span-6 sm:col-span-3">
                        <label
                          htmlFor="Password"
                          className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                        >
                          Password
                        </label>

                        <input
                          type="password"
                          value={
                            formFields.Password
                          }
                          onChange={
                            handleInputChange
                          }
                          name="Password"
                          className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                          required
                        />
                      </div>

                      <div className="mt-7 col-span-6 sm:col-span-3">
                        <label
                          htmlFor="PasswordConfirmation"
                          className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                        >
                          Confirm Password
                        </label>

                        <input
                          type="password"
                          value={
                            formFields.PasswordConfirmation
                          }
                          onChange={
                            handleInputChange
                          }
                          name="PasswordConfirmation"
                          className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                          required
                        />
                      </div>
                      <div className="col-span-76 sm:flex sm:items-center sm:gap-4">
                        <button
                          type="submit"
                          className="inline-block shrink-0 rounded-md border border-green-600 bg-green-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                        >
                          Submit
                        </button>{" "}
                      </div>
                    </form>
                    <br />
                    <span>
                      {" "}
                      Don&apos;t need to reset ?
                    </span>{" "}
                    <button
                      onClick={() =>
                        setActiveForm("login")
                      }
                      style={{
                        color: "#53BF4C",
                      }}
                    >
                      {" "}
                      <u>Login</u>
                    </button>
                  </motion.div>
                )}

                {activeForm ===
                  "activationcode" && (
                  <motion.div>
                    {" "}
                    <br />
                    <img
                      style={{
                        maxWidth: "100%",
                        height: 150,
                      }}
                      src={logo}
                    />
                    <hr />
                    <h3
                      style={{ color: "#53BF4C" }}
                      className="mt-6 text-4xl font-bold text-black sm:text-1xl md:text-1xl"
                    >
                      Merchant portal login
                    </h3>
                    <p>
                      Account created
                      successfully, OTP code has
                      been sent to{" "}
                      {formFields.Phonenumber}
                    </p>
                    {inputError && (
                      <p style={{ color: "red" }}>
                        {inputError}
                      </p>
                    )}
                    {inputsuccess && (
                      <p
                        style={{ color: "green" }}
                      >
                        {inputsuccess}
                      </p>
                    )}
                    <form
                      onSubmit={handleOTPSubmit}
                      className="grid grid-cols-6 gap-10"
                    >
                      <div className="mt-7 col-span-6 sm:col-span-6">
                        <label
                          htmlFor="FirstName"
                          className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                        >
                          Enter OTP Code
                        </label>

                        <input
                          type="number"
                          onChange={
                            handleInputChangeOTP
                          }
                          placeholder="Code"
                          value={formOTP.OTP}
                          name="OTP"
                          required
                          className="p-2 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                        />
                      </div>
                      <div className="col-span-76 sm:flex sm:items-center sm:gap-4">
                        <button
                          type="submit"
                          className="inline-block shrink-0 rounded-md border border-green-600 bg-green-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                        >
                          Activate Account
                        </button>{" "}
                      </div>
                    </form>
                    <br />
                    <span>
                      {" "}
                      Did receive OTP code ?
                    </span>
                    <a
                      href="javascript:void(0)"
                      onClick={handleResendOTP}
                      style={{
                        color: "#53BF4C",
                      }}
                    >
                      {" "}
                      <u>Resend OTP Code</u>
                    </a>
                  </motion.div>
                )}

                {activeForm === "form1" && (
                  <div
                    className="scroll-container"
                    style={{
                      maxHeight:
                        "calc(100vh - 2rem)",
                    }}
                  >
                    <motion.div>
                      <h3
                        style={{
                          color: "#53BF4C",
                        }}
                        className="mt-6 text-2xl font-bold text-black sm:text-1xl md:text-1xl"
                      >
                        <hr />
                        <br />
                        Business portal sign up
                      </h3>
                      <h2
                        ref={errorRef}
                        style={{
                          color: "#53BF4C",
                          fontWeight: "bolder",
                        }}
                      >
                        Merchants / Agents / Bulk
                        Payers
                      </h2>
                      <p>Personal Details</p>
                      {inputError && (
                        <p
                          style={{ color: "red" }}
                        >
                          {inputError}
                        </p>
                      )}
                      <form
                        className="grid grid-cols-6 gap-6"
                        onSubmit={
                          handleSignSubmit
                        }
                      >
                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            First Name
                          </label>

                          <input
                            type="text"
                            placeholder="e.g. John "
                            value={
                              formFields.FirstName
                            }
                            onChange={
                              handleInputChange
                            }
                            name="FirstName"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Surname
                          </label>

                          <input
                            type="text"
                            value={
                              formFields.Surname
                            }
                            onChange={
                              handleInputChange
                            }
                            placeholder="e.g. Doe"
                            name="Surname"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-2">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Phone number
                          </label>

                          <input
                            type="number"
                            value={
                              formFields.Phonenumber
                            }
                            onChange={
                              handleInputChange
                            }
                            placeholder="e.g. 263777777777"
                            name="Phonenumber"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            onInput={(e) => {
                              e.target.value =
                                Math.max(
                                  0,
                                  parseInt(
                                    e.target.value
                                  )
                                )
                                  .toString()
                                  .slice(0, 12);
                            }}
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-2">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Email
                          </label>

                          <input
                            type="email"
                            value={
                              formFields.Email
                            }
                            onChange={
                              handleInputChange
                            }
                            placeholder="e.g. johndoe@gmail.com"
                            name="Email"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-2">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            ID Number
                          </label>

                          <input
                            type="text"
                            value={
                              formFields.Idnum
                            }
                            onChange={
                              handleInputChange
                            }
                            placeholder="e.g. 401010892Q22"
                            name="Idnum"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-6">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Date of birth
                          </label>
                          <input
                            type="date"
                            value={formFields.Dob}
                            onChange={
                              handleInputChange
                            }
                            name="Dob"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            max="2006-01-01"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="Password"
                            className="block text-sm font-medium text-black"
                          >
                            Password
                          </label>

                          <input
                            type="password"
                            value={
                              formFields.Password
                            }
                            onChange={
                              handleInputChange
                            }
                            name="Password"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="PasswordConfirmation"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Confirm Password
                          </label>

                          <input
                            type="password"
                            value={
                              formFields.PasswordConfirmation
                            }
                            onChange={
                              handleInputChange
                            }
                            name="PasswordConfirmation"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="gender"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Gender
                          </label>

                          <div className="flex mt-2">
                            <label className="inline-flex items-center mr-4">
                              <input
                                type="radio"
                                id="male"
                                name="gender"
                                value="Male"
                                checked={
                                  gender ===
                                  "Male"
                                }
                                onChange={
                                  handleGenderChange
                                }
                                className="size-5 rounded-md border-gray-200 bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:focus:ring-offset-gray-900"
                                required
                              />
                              <span className="ml-2">
                                Male
                              </span>
                            </label>

                            <label className="inline-flex items-center">
                              <input
                                type="radio"
                                id="female"
                                name="gender"
                                value="Female"
                                checked={
                                  gender ===
                                  "Female"
                                }
                                onChange={
                                  handleGenderChange
                                }
                                className="size-5 rounded-md border-gray-200 bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:focus:ring-offset-gray-900"
                                required
                              />
                              <span className="ml-2">
                                Female
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-span-5">
                          <label
                            htmlFor="MarketingAccept"
                            className="flex gap-4"
                          >
                            <input
                              type="checkbox"
                              id="MarketingAccept"
                              name="marketing_accept"
                              className="size-5 rounded-md border-gray-200 bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:focus:ring-offset-gray-900"
                              required
                            />

                            <p className="text-l text-gray-500 dark:text-gray-400">
                              I accept the{" "}
                              <button
                                onClick={
                                  downTandC
                                }
                                type="button"
                                className="text-gray-700 underline dark:text-gray-200"
                              >
                                {" "}
                                terms and
                                conditions{" "}
                              </button>{" "}
                              and{" "}
                              <button
                                onClick={downPP}
                                className="text-gray-700 underline dark:text-gray-200"
                              >
                                {" "}
                                privacy policy{" "}
                              </button>{" "}
                              by clicking
                            </p>
                          </label>
                        </div>

                        <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                          <button
                            type="submit"
                            className="inline-block shrink-0 rounded-md border border-green-600 bg-green-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                          >
                            Next
                          </button>{" "}
                          <button
                            onClick={() =>
                              setActiveForm(
                                "login"
                              )
                            }
                            className="inline-block shrink-0 rounded-md border border-gray-600 bg-white-600 px-12 py-3 text-sm font-medium text-green-600 transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                          >
                            Login
                          </button>
                        </div>
                      </form>
                    </motion.div>
                  </div>
                )}

                {activeForm === "info" && (
                  <div
                    className="scroll-container"
                    style={{
                      maxHeight:
                        "calc(100vh - 2rem)",
                    }}
                  >
                    <motion.div>
                      <h3
                        style={{
                          color: "#53BF4C",
                        }}
                        className="mt-6 text-2xl font-bold text-black sm:text-1xl md:text-1xl"
                      >
                        <hr />
                        <br />
                        Application Conditions
                      </h3>
                      <br />
                      <b>
                        {" "}
                        Please note that your
                        liquidation or channel
                        partner application
                        request can be rejected
                        because of the following
                        reasons.
                      </b>
                      <br />
                      <br />
                      <b>Applicant Details</b>
                      <br />
                      <ol className="decorated-list">
                        <li>
                          If applicant name is not
                          authorised
                          (authorisation can be
                          done on letterhead) or
                          not part of the
                          directors/ office
                          bearers of the
                          organisation in case of
                          companies, societies GVT
                          departments, churches,
                          schools, trust.
                        </li>
                        <li>
                          The name of the person
                          submitting the
                          application is not the
                          same as the channel
                          partner name in case of
                          sole traders.
                        </li>
                      </ol>
                      <b>Documents Submitted</b>
                      <ol className="decorated-list">
                        <li>
                          Documents uploaded are
                          not certified by the
                          POLICE and are not
                          VISIBLE. If documents
                          submitted are not clear
                          please resubmit clear
                          documents.
                        </li>
                        <li>
                          Templates used are
                          wrong. Ensure you
                          download standard
                          templates on the system
                          using the link provided
                          against the respective
                          document. i.e. A
                          template for passport
                          photos, Mandate and List
                          of Agent Codes, Mandate
                          and Indemnity Form,
                          Beneficial Form is
                          provided for under the
                          section for documents
                          where applicable.
                        </li>
                      </ol>
                      <b>
                        National Identity
                        Documents and Passport
                        Photos
                      </b>
                      <ol className="decorated-list">
                        <li>
                          Only one National ID is
                          provided in case of
                          companies, societies,
                          schools, churches,
                          trusts, ensure you
                          provide National ID’s
                          for at least two
                          directors/ office
                          bearers. Ensure you scan
                          and upload ID’s provided
                          as one document.
                        </li>
                      </ol>
                      <b>
                        Proof of Address and
                        Physical Address
                      </b>
                      <ol className="decorated-list">
                        <li>
                          Only one proof of
                          address is provided in
                          case of companies,
                          societies, schools,
                          churches, trusts, ensure
                          you provide proof of
                          addresses for at least
                          two directors/ office
                          bearers. Ensure you scan
                          and upload both proof of
                          addressed as one
                          document.
                        </li>

                        <li>
                          {" "}
                          Proof of address is more
                          than 4 months old.
                          Please ensure proof of
                          address is not more than
                          4 months old.
                        </li>

                        <li>
                          Affidavit is not
                          attached were proof of
                          address is in a
                          different name from the
                          applicant. Please attach
                          an affidavit where proof
                          of address is in a
                          different name.
                        </li>

                        <li>
                          {" "}
                          Incomplete Physical
                          Address. The Physical
                          address for contact
                          person is their home
                          address and not the
                          business address unless
                          if these are the same.
                        </li>
                      </ol>
                      <b>CR14</b>
                      <ol className="decorated-list">
                        <li>
                          CR14 does not have the
                          list of directors.
                          Ensure you scan and
                          upload both pages of the
                          CR14 as one document.
                        </li>
                      </ol>
                      <b>
                        Letterhead Instruction
                      </b>
                      <ol className="decorated-list">
                        <li>
                          Letterhead instruction
                          is not signed by
                          authorised signatories
                          for applicable
                          organisation types.
                          Ensure letterhead
                          instruction is signed by
                          authorised signatories.
                          In case of new account
                          application or
                          reactivation specify
                          number of accounts
                          required on letterhead.
                        </li>
                      </ol>
                      <b>Bank Details</b>
                      <ol className="decorated-list">
                        <li>
                          Bank account name is not
                          the same as Agent Name
                          on O&apos;mari.
                        </li>
                        <li>
                          Bank Statement is not
                          stamped by the Bank and
                          does not show the Bank
                          Account Name.
                        </li>
                        <li>
                          Incorrect Organisation
                          type. Ensure you choose
                          the correct organisation
                          type and provide
                          documents required for
                          that type of
                          organisation.
                        </li>
                      </ol>
                      <b>Trading Licence</b>
                      <ol className="decorated-list">
                        <li>
                          Trading Licence is
                          expired.
                        </li>
                        <li>
                          Trading licence is in a
                          different name from
                          Applicant. Where trading
                          licence is in a
                          different name from
                          applicant name or
                          company please attach
                          affidavit. Scan and
                          Upload trading licence
                          and affidavit as one
                          document.
                        </li>
                      </ol>
                      <b>
                        Beneficial form is not
                        completed
                      </b>
                      <ol className="decorated-list">
                        <li>
                          Beneficial form is
                          required and should be
                          fully completed for
                          normal account
                          application and KYC
                          Update.
                        </li>
                      </ol>
                      <br />
                      <button
                        onClick={() =>
                          setActiveForm("form2")
                        }
                        className="mt-2 inline-block shrink-0 rounded-md border border-green-600 bg-green-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white "
                      >
                        Next
                      </button>{" "}
                      <br />
                      <br />
                    </motion.div>
                  </div>
                )}

                {activeForm === "form2" && (
                  <div
                    className="scroll-container"
                    style={{
                      maxHeight:
                        "calc(100vh - 2rem)",
                    }}
                  >
                    <motion.div>
                      <h3
                        style={{
                          color: "#53BF4C",
                        }}
                        className="mt-6 text-2xl font-bold text-black sm:text-1xl md:text-1xl"
                      >
                        <hr />
                        <br />
                        Create Business Profile
                      </h3>
                      <span
                        style={{
                          color: "#53BF4C",
                        }}
                      >
                        Step 1{" "}
                      </span>

                      <p ref={errorRef}>
                        Organisation{" "}
                      </p>
                      {inputErrorBS && (
                        <p
                          style={{ color: "red" }}
                        >
                          {inputErrorBS}
                        </p>
                      )}
                      <form
                        onSubmit={
                          handleBusinessSubmit
                        }
                        className="mt-8 grid grid-cols-6 gap-6"
                      >
                        <div className="mt-7 col-span-6 sm:col-span-6">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Select Business Type
                          </label>

                          <select
                            type="text"
                            value={
                              businessFields.BusType
                            }
                            onChange={
                              handleBusinessTypeChanger
                            }
                            name="BusType"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          >
                            <option value="">
                              Business Types
                            </option>
                            <option value="Merchant">
                              Merchant
                            </option>
                            <option value="Agent">
                              Agent
                            </option>
                            <option value="Bulk Payer">
                              Bulk Payer
                            </option>
                          </select>
                        </div>
                        <div className="mt-3 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Name of Organisation{" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            value={
                              businessFields.OrgName
                            }
                            onChange={
                              handleBusinessChange
                            }
                            name="OrgName"
                            placeholder="My Company"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-3 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Organisation Type
                            (Sole trader){" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>

                          <input
                            type="text"
                            value={
                              businessFields.OrgType
                            }
                            onChange={
                              handleBusinessChange
                            }
                            name="OrgType"
                            placeholder="e.g. Supermarket"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-3 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Region / City{" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <select
                            type="text"
                            value={
                              businessFields.Region
                            }
                            onChange={
                              handleBusinessTypeChange
                            }
                            name="Region"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          >
                            <option
                              value=""
                              disabled
                            >
                              Select a Region /
                              City
                            </option>
                            {citiesAndTowns.map(
                              (citiesAndTown) => (
                                <option
                                  key={
                                    citiesAndTown
                                  }
                                  value={
                                    citiesAndTown
                                  }
                                >
                                  {citiesAndTown}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        <div className="mt-3 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Contact{" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>

                          <input
                            type="number"
                            value={
                              businessFields.Contact
                            }
                            onChange={
                              handleBusinessChange
                            }
                            name="Contact"
                            placeholder="e.g. 263789281232, 26329273600/3"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-3 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Email{" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>

                          <input
                            type="email"
                            value={
                              businessFields.EmailOrg
                            }
                            onChange={
                              handleBusinessChange
                            }
                            name="EmailOrg"
                            placeholder="sales@mycompany.co.zw"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-3 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Industry{" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>

                          <input
                            type="text"
                            value={
                              businessFields.IndustryOrg
                            }
                            onChange={
                              handleBusinessChange
                            }
                            placeholder="e.g. Construction"
                            name="IndustryOrg"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-3 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Preferred Name for the
                            account{" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>

                          <input
                            type="text"
                            value={
                              businessFields.PreferredName
                            }
                            onChange={
                              handleBusinessChange
                            }
                            name="PreferredName"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className=" mt-3 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="Password"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Number of Employees{" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>

                          <input
                            type="number"
                            value={
                              businessFields.NumofEmployees
                            }
                            onChange={
                              handleBusinessChange
                            }
                            placeholder="e.g. 12"
                            name="NumofEmployees"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-3 col-span-6 sm:col-span-6">
                          <label
                            htmlFor="PasswordConfirmation"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Average Annual Revenue
                            {" USD "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <select
                            type="text"
                            value={
                              businessFields.AverageRev
                            }
                            onChange={
                              handleBusinessTypeChange
                            }
                            name="AverageRev"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          >
                            <option value="">
                              Selcect Amount
                            </option>
                            <option value="<1000">
                              less then $1000
                            </option>
                            <option value=">5000">
                              more than $5000
                            </option>
                            <option value=">10000">
                              more than $10000
                            </option>
                            <option value=">10000">
                              more than $15000
                            </option>
                          </select>
                        </div>

                        <div className="mt-3 col-span-6 sm:flex sm:items-center sm:gap-4">
                          <button
                            type="submit"
                            className="inline-block shrink-0 rounded-md border border-green-600 bg-green-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                          >
                            Next
                          </button>{" "}
                        </div>

                        <span
                          style={{
                            fontSize: "8",
                          }}
                          className="invisible"
                        >
                          Business contact details
                        </span>
                      </form>
                    </motion.div>
                  </div>
                )}

                {activeForm === "form3" && (
                  <div
                    className="scroll-container"
                    style={{
                      maxHeight:
                        "calc(100vh - 2rem)",
                    }}
                  >
                    <motion.div>
                      <h3
                        style={{
                          color: "#53BF4C",
                        }}
                        className="mt-6 text-2xl font-bold text-black sm:text-1xl md:text-1xl"
                      >
                        <hr />
                        <br />
                        Create Business Profile
                      </h3>
                      <span
                        style={{
                          color: "#53BF4C",
                        }}
                      >
                        Step 2{" "}
                      </span>
                      <p ref={errorRef}>
                        Business Details
                      </p>
                      {inputAddError && (
                        <div
                          style={{
                            color: "red",
                          }}
                        >
                          {inputAddError}
                        </div>
                      )}
                      <form
                        onSubmit={
                          handleBusinessDetails
                        }
                        className="mt-8 grid grid-cols-6 gap-6"
                      >
                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Business Address{" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            value={
                              businessFields.BusinessAdd
                            }
                            onChange={
                              handleBusinessChange
                            }
                            placeholder="e.g. 11 Willowvale, Harare"
                            name="BusinessAdd"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Business Address 2
                          </label>

                          <input
                            type="text"
                            value={
                              businessFields.BusinessAdd2
                            }
                            onChange={
                              handleBusinessChange
                            }
                            placeholder="e.g. 2 Rollestone Close, Harare"
                            name="BusinessAdd2"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-12 sm:col-span-3">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            City
                          </label>
                          <select
                            type="text"
                            value={
                              businessFields.city
                            }
                            onChange={
                              handleBusinessTypeChange
                            }
                            name="city"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          >
                            <option
                              value=""
                              disabled
                            >
                              Select a City / Town
                            </option>
                            {citiesAndTowns.map(
                              (citiesAndTown) => (
                                <option
                                  key={
                                    citiesAndTown
                                  }
                                  value={
                                    citiesAndTown
                                  }
                                >
                                  {citiesAndTown}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        <div className="mt-7 col-span-12 sm:col-span-3">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Province
                          </label>
                          <select
                            type="text"
                            value={
                              businessFields.province
                            }
                            onChange={
                              handleBusinessTypeChange
                            }
                            name="province"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          >
                            <option
                              value=""
                              disabled
                            >
                              Select a Province
                            </option>
                            {provinces.map(
                              (province) => (
                                <option
                                  key={province}
                                  value={province}
                                >
                                  {province}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        <div className="mt-7 col-span-6 sm:flex sm:items-center sm:gap-4">
                          <button
                            type="submit"
                            className="inline-block shrink-0 rounded-md border border-green-600 bg-green-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                          >
                            Next
                          </button>{" "}
                          <br />
                          <button
                            onClick={() =>
                              setActiveForm(
                                "form2"
                              )
                            }
                            className="inline-block shrink-0 rounded-md border border-gray-600 bg-white-600 px-12 py-3 text-sm font-medium text-green-600 transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                          >
                            Back
                          </button>
                        </div>

                        <span
                          style={{
                            fontSize: "8",
                          }}
                          className="invisible"
                        >
                          Business contact details
                        </span>
                      </form>
                    </motion.div>
                  </div>
                )}

                {activeForm === "docupload" && (
                  <div
                    className="scroll-container"
                    style={{
                      maxHeight:
                        "calc(100vh - 2rem)",
                    }}
                  >
                    <motion.div>
                      <h3
                        style={{
                          color: "#53BF4C",
                        }}
                        className="mt-6 text-2xl font-bold text-black sm:text-1xl md:text-1xl"
                      >
                        <hr />
                        <br />
                        Create Business Profile
                      </h3>
                      <span
                        style={{
                          color: "#53BF4C",
                        }}
                      >
                        Step 3{" "}
                      </span>
                      <p ref={errorRef}>
                        Business Documents
                      </p>
                      {inputError && (
                        <p
                          style={{ color: "red" }}
                        >
                          {inputError}
                        </p>
                      )}
                      <form
                        onSubmit={
                          handleBusinessDocs
                        }
                        className="mt-4 grid grid-cols-6 gap-6"
                      >
                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Certified copy of
                            Certificate of
                            Incorporation
                          </label>
                          <input
                            type="file"
                            onChange={(event) =>
                              handleFileChange3(
                                event,
                                0
                              )
                            }
                            name="file3"
                            placeholder="e.g. 11 Willowvale, Harare"
                            className="p-1 h-300 mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                          {isFileUploaded3 && (
                            <CheckCircleOutlined
                              style={{
                                color: "green",
                                marginLeft: "8px",
                              }}
                            />
                          )}
                          {fileinputError3 && (
                            <div
                              style={{
                                color: "red",
                              }}
                            >
                              {fileinputError3}
                            </div>
                          )}{" "}
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Certified copy of Cr14
                          </label>
                          <input
                            type="file"
                            onChange={(event) =>
                              handleFileChange4(
                                event,
                                0
                              )
                            }
                            name="file4"
                            placeholder="e.g. 2 Rollestone Close, Harare"
                            className="p-1 h-300 mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                          {isFileUploaded4 && (
                            <CheckCircleOutlined
                              style={{
                                color: "green",
                                marginLeft: "8px",
                              }}
                            />
                          )}
                          {fileinputError4 && (
                            <div
                              style={{
                                color: "red",
                              }}
                            >
                              {fileinputError4}
                            </div>
                          )}{" "}
                        </div>

                        <div className="mt-4 col-span-6 sm:col-span-6">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Coloured passport
                            photo of the
                            Directors.
                          </label>
                          <input
                            type="file"
                            onChange={(event) =>
                              handleFileChange5(
                                event,
                                0
                              )
                            }
                            name="file5"
                            placeholder="e.g. Harare"
                            className="p-1 h-300 mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                          {isFileUploaded5 && (
                            <CheckCircleOutlined
                              style={{
                                color: "green",
                                marginLeft: "8px",
                              }}
                            />
                          )}
                          {fileinputError5 && (
                            <div
                              style={{
                                color: "red",
                              }}
                            >
                              {fileinputError5}
                            </div>
                          )}{" "}
                        </div>

                        <div className="mt-4 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Certified copy of ID
                            of 1st Director
                          </label>
                          <input
                            type="file"
                            onChange={(event) =>
                              handleFileChange6(
                                event,
                                0
                              )
                            }
                            name="file6"
                            placeholder="e.g. Harare"
                            className="p-1 h-300 mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                          {isFileUploaded6 && (
                            <CheckCircleOutlined
                              style={{
                                color: "green",
                                marginLeft: "8px",
                              }}
                            />
                          )}
                          {fileinputError6 && (
                            <div
                              style={{
                                color: "red",
                              }}
                            >
                              {fileinputError6}
                            </div>
                          )}{" "}
                        </div>

                        <div className="mt-4 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Certified copy of ID
                            of 2nd Director
                          </label>
                          <input
                            type="file"
                            onChange={(event) =>
                              handleFileChange7(
                                event,
                                0
                              )
                            }
                            name="file7"
                            placeholder="e.g. Harare"
                            className="p-1 h-300 mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />{" "}
                          {isFileUploaded7 && (
                            <CheckCircleOutlined
                              style={{
                                color: "green",
                                marginLeft: "8px",
                              }}
                            />
                          )}
                          {fileinputError7 && (
                            <div
                              style={{
                                color: "red",
                              }}
                            >
                              {fileinputError7}
                            </div>
                          )}{" "}
                        </div>

                        <div className="mt-4 col-span-6 sm:col-span-6">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Proof of Residence of
                            the directors or proof
                            of physical address
                            for business premises
                          </label>
                          <input
                            type="file"
                            onChange={(event) =>
                              handleFileChange8(
                                event,
                                0
                              )
                            }
                            name="file8"
                            placeholder="e.g. Mashonaland South"
                            className="p-1 h-300 mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                          {isFileUploaded8 && (
                            <CheckCircleOutlined
                              style={{
                                color: "green",
                                marginLeft: "8px",
                              }}
                            />
                          )}
                          {fileinputError8 && (
                            <div
                              style={{
                                color: "red",
                              }}
                            >
                              {fileinputError8}
                            </div>
                          )}{" "}
                          <br />
                          <br />
                          <span>
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              * Please note *
                            </span>
                            <br />
                            For all forms of
                            business, proof of
                            residence can be :
                            <br />
                            <ul>
                              <li>
                                • A bank statement
                                or utility bill
                                that is not older
                                than 3 months
                              </li>
                              <li>
                                • A valid trading
                                license showing
                                the business
                                address{" "}
                              </li>
                              <li>
                                • A copy of lease
                                agreement for
                                rented premises
                                supported by
                                landowner’s ID{" "}
                              </li>
                              <li>
                                • An affidavit
                                with a
                                Commissioner of
                                Oaths or Police
                                stamp.{" "}
                              </li>
                            </ul>
                            <br />
                            <ul>
                              In rural areas where
                              this may not always
                              be available :{" "}
                              <br />{" "}
                              <li>
                                {" "}
                                • A copy of ID of
                                the landowner and
                                a letter from the
                                landowner
                                confirming that
                                they are operating
                                from their
                                premises).
                              </li>
                              <li>
                                {" "}
                                • Valid Trading
                                Licence
                              </li>
                            </ul>
                          </span>
                        </div>

                        <div className="mt-7 col-span-6 sm:flex sm:items-center sm:gap-4">
                          <button
                            type="submit"
                            className="inline-block shrink-0 rounded-md border border-green-600 bg-green-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                          >
                            Next
                          </button>{" "}
                          <button
                            onClick={() =>
                              setActiveForm(
                                "form3"
                              )
                            }
                            className="inline-block shrink-0 rounded-md border border-gray-600 bg-white-600 px-12 py-3 text-sm font-medium text-green-600 transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                          >
                            Back
                          </button>
                        </div>

                        <span
                          style={{
                            fontSize: "8",
                          }}
                          className="invisible"
                        >
                          Business contact details
                        </span>
                      </form>
                    </motion.div>
                  </div>
                )}

                {activeForm === "form4" && (
                  <div
                    className="scroll-container"
                    style={{
                      maxHeight:
                        "calc(100vh - 2rem)",
                    }}
                  >
                    <motion.div>
                      <h3
                        style={{
                          color: "#53BF4C",
                        }}
                        className="mt-6 text-2xl font-bold text-black sm:text-1xl md:text-1xl"
                      >
                        <hr />
                        <br />
                        Create Business Profile
                      </h3>
                      <span
                        style={{
                          color: "#53BF4C",
                        }}
                      >
                        Step 4{" "}
                      </span>
                      <p ref={errorRef}>
                        Banking Details
                      </p>
                      {inputError && (
                        <p
                          style={{ color: "red" }}
                        >
                          {inputError}
                        </p>
                      )}
                      <form
                        onSubmit={
                          handleBankingDetails
                        }
                        className="mt-8 grid grid-cols-6 gap-6"
                      >
                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Account Number{" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>

                          <input
                            type="number"
                            value={
                              businessFields.AN
                            }
                            onChange={
                              handleBusinessChange
                            }
                            placeholder="e.g. 1010001010"
                            name="AN"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Account holder Name{" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>

                          <input
                            type="text"
                            value={
                              businessFields.AHN
                            }
                            onChange={
                              handleBusinessChange
                            }
                            placeholder="e.g. John Doe"
                            name="AHN"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-2">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Bank
                          </label>

                          <select
                            type="text"
                            value={
                              businessFields.Bank
                            }
                            onChange={
                              handleBusinessChange
                            }
                            name="Bank"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          >
                            <option value="">
                              Select Bank
                            </option>
                            <option value="STEWARD">
                              STEWARD
                            </option>
                            <option value="FBC">
                              FBC
                            </option>
                            <option value="NEDBANK">
                              NEDBANK
                            </option>
                            <option value="BANC ABC">
                              BANC ABC
                            </option>
                            <option value="CABS">
                              CABS
                            </option>
                            <option value="ZB">
                              ZB
                            </option>
                            <option value="NMB">
                              NMB
                            </option>
                            <option value="STANBIC">
                              STANBIC
                            </option>
                            <option value="POSB">
                              POSB
                            </option>
                            <option value="CBZ">
                              CBZ
                            </option>
                            <option value="STANCHART">
                              STANCHART
                            </option>
                            <option value="ECOBANK">
                              ECOBANK
                            </option>
                            <option value="GETBUCKS">
                              GETBUCKS
                            </option>
                            <option value="NBS">
                              NBS
                            </option>
                            <option value="FIRSTCAPITAL BANK">
                              FIRSTCAPITAL BANK
                            </option>
                            <option value="RBZ">
                              RBZ
                            </option>
                            <option value="AGRIBANK">
                              AGRIBANK
                            </option>
                          </select>
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-2">
                          <label
                            htmlFor="TFTB"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Transfer funds to bank
                          </label>

                          <select
                            value={
                              businessFields.TFTB
                            }
                            onChange={
                              handleBusinessChange
                            }
                            name="TFTB"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          >
                            <optgroup label="Frequency">
                              <option value="">
                                Select Frequency
                              </option>
                              <option value="WEEKLY">
                                WEEKLY
                              </option>
                              <option value="MONTHLY">
                                MONTHLY
                              </option>
                              <option value="UPON REQUEST">
                                UPON REQUEST
                              </option>
                            </optgroup>
                          </select>
                        </div>

                        {/* Conditionally render the date field based on the selected frequency */}
                        {businessFields.TFTB ===
                          "WEEKLY" && (
                          <div className="mt-7 col-span-6 sm:col-span-2">
                            <label
                              htmlFor="TDate"
                              className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                            >
                              Select Transfer Day
                            </label>

                            <select
                              value={
                                businessFields.TDate
                              }
                              onChange={
                                handleBusinessChange
                              }
                              name="TDate"
                              className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                              required
                            >
                              <option value="">
                                -- Select a day --
                              </option>
                              <option value="Monday">
                                Monday
                              </option>
                              <option value="Tuesday">
                                Tuesday
                              </option>
                              <option value="Wednesday">
                                Wednesday
                              </option>
                              <option value="Thursday">
                                Thursday
                              </option>
                              <option value="Friday">
                                Friday
                              </option>
                            </select>
                          </div>
                        )}

                        {businessFields.TFTB ===
                          "MONTHLY" && (
                          <div className="mt-7 col-span-6 sm:col-span-2">
                            <label
                              htmlFor="TDate"
                              className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                            >
                              Transfer Date
                            </label>

                            <input
                              type="date"
                              value={
                                businessFields.TDate
                              }
                              onChange={
                                handleBusinessChange
                              }
                              name="TDate"
                              className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                              required
                            />
                          </div>
                        )}

                        {/* Hide the date input when 'UPON REQUEST' is selected */}
                        {businessFields.TFTB ===
                          "UPON REQUEST" && (
                          <div className="mt-7 col-span-6 sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                              Date is upon request
                            </label>
                          </div>
                        )}

                        <div className="mt-7 col-span-6 sm:flex sm:items-center sm:gap-4">
                          <button
                            type="submit"
                            className="inline-block shrink-0 rounded-md border border-green-600 bg-green-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                          >
                            Next
                          </button>{" "}
                          <button
                            onClick={() =>
                              setActiveForm(
                                "form3"
                              )
                            }
                            className="inline-block shrink-0 rounded-md border border-gray-600 bg-white-600 px-12 py-3 text-sm font-medium text-green-600 transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                          >
                            Back
                          </button>
                        </div>

                        <span
                          style={{
                            fontSize: "8",
                          }}
                          className="invisible"
                        >
                          Business contact details
                        </span>
                      </form>
                    </motion.div>
                  </div>
                )}

                {activeForm === "form5" && (
                  <div
                    className="scroll-container"
                    style={{
                      maxHeight:
                        "calc(100vh - 2rem)",
                    }}
                  >
                    <motion.div>
                      <h3
                        style={{
                          color: "#53BF4C",
                        }}
                        className="mt-6 text-2xl font-bold text-black sm:text-1xl md:text-1xl"
                      >
                        <hr />
                        <br />
                        Create Business Profile
                      </h3>
                      <span
                        style={{
                          color: "#53BF4C",
                        }}
                      >
                        Step 4{" "}
                      </span>
                      <p ref={errorRef}>
                        Contact Person/s
                      </p>
                      {inputError && (
                        <p
                          style={{ color: "red" }}
                        >
                          {inputError}
                        </p>
                      )}
                      <form
                        onSubmit={
                          handlePersonDetails
                        }
                        className="grid grid-cols-6 gap-6"
                      >
                        <div className="mt-7 col-span-6 sm:col-span-1">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Title
                          </label>

                          <select
                            type="text"
                            value={
                              personsFields.title
                            }
                            onChange={
                              handlePersonChange
                            }
                            name="title"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          >
                            <option value="">
                              Select
                            </option>
                            <option value="MR">
                              MR
                            </option>
                            <option value="MRS">
                              MRS
                            </option>
                            <option value="MS">
                              MS
                            </option>
                            <option value="DR">
                              DR
                            </option>
                            <option value="MISS">
                              MISS
                            </option>
                            <option value="PROFESSOR">
                              PROFESSOR
                            </option>
                          </select>
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-2">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            First Name
                          </label>

                          <input
                            type="text"
                            placeholder="e.g. John "
                            value={
                              personsFields.fn
                            }
                            onChange={
                              handlePersonChange
                            }
                            name="fn"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Surname
                          </label>

                          <input
                            type="text"
                            value={
                              personsFields.ln
                            }
                            onChange={
                              handlePersonChange
                            }
                            placeholder="e.g. Doe"
                            name="ln"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-2">
                          <label
                            htmlFor="FirstName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Phone number
                          </label>

                          <input
                            type="text"
                            value={
                              personsFields.phon
                            }
                            onChange={
                              handlePersonChange
                            }
                            placeholder="e.g. 26377777777"
                            name="phon"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-2">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Email
                          </label>

                          <input
                            type="text"
                            placeholder="e.g. johndoe@gmail.com"
                            value={
                              personsFields.email
                            }
                            onChange={
                              handlePersonChange
                            }
                            name="email"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-2">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Designate type
                          </label>

                          <select
                            type="text"
                            value={
                              personsFields.dtyp
                            }
                            onChange={
                              handlePersonChange
                            }
                            name="dtyp"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          >
                            <option value="">
                              Select
                            </option>
                            <option value="OWNER">
                              OWNER
                            </option>
                            <option value="DIRECTOR">
                              DIRECTOR
                            </option>
                            <option value="CHAIRMAN">
                              CHAIRMAN
                            </option>
                            <option value="ADMINISTRATOR">
                              ADMINISTRATOR
                            </option>
                            <option value="TREASURER">
                              TREASURER
                            </option>
                            <option value="TRUSTEE">
                              TRUSTEE
                            </option>
                            <option value="COMMITEE MEMBER">
                              COMMITEE MEMBER
                            </option>
                            <option value="ACCOUNTANT">
                              ACCOUNTANT
                            </option>
                            <option value="CHIEF ACCOUNTANT">
                              CHIEF ACCOUNTANT
                            </option>
                            <option value="SECRETARY">
                              SECRETARY
                            </option>
                            <option value="CEO">
                              CEO
                            </option>
                            <option value="MANAGING DIRECTOR">
                              MANAGING DIRECTOR
                            </option>
                            <option
                              value="CHIEF FINANCIAL
                              OFFICER"
                            >
                              CHIEF FINANCIAL
                              OFFICER
                            </option>
                            <option value="FINANCIAL CONTROLLER">
                              FINANCIAL CONTROLLER
                            </option>
                            <option
                              value="CHIEF OPERATIONS
                              OFFICER"
                            >
                              CHIEF OPERATIONS
                              OFFICER
                            </option>
                            <option value="FINANCE MANAGER">
                              FINANCE MANAGER
                            </option>
                            <option value="GENERAL MANAGER">
                              GENERAL MANAGER
                            </option>
                            <option value="CHIEF SALES OFFICER">
                              CHIEF SALES OFFICER
                            </option>
                            <option value="PRODUCT MANAGER">
                              PRODUCT MANAGER
                            </option>
                            <option value="ACCOUNT EXECUTIVE">
                              ACCOUNT EXECUTIVE
                            </option>
                            <option value="SHOP MANAGER">
                              SHOP MANAGER
                            </option>
                            <option value="HEADMASTER">
                              HEADMASTER
                            </option>
                            <option value="DEPUTY HEADMASTER">
                              DEPUTY HEADMASTER
                            </option>
                            <option value="SENIOR TEACHER">
                              SENIOR TEACHER
                            </option>
                            <option value="WARDEN">
                              WARDEN
                            </option>
                            <option value="PRIEST">
                              PRIEST
                            </option>
                            <option value="OVERSEER">
                              OVERSEER
                            </option>
                            <option value="PRESIDENT">
                              PRESIDENT
                            </option>
                            <option value="DEPUTY PRESIDENT">
                              DEPUTY PRESIDENT
                            </option>
                            <option value="BURSAR">
                              BURSAR
                            </option>
                            <option value="PRINCIPAL">
                              PRINCIPAL
                            </option>
                            <option value="REGISTRAR">
                              REGISTRAR
                            </option>
                          </select>
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-6">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            National ID (e.g
                            123456789E90)
                          </label>

                          <input
                            type="text"
                            value={
                              personsFields.idnm
                            }
                            onChange={
                              handlePersonChange
                            }
                            placeholder="e.g. 401010892Q22"
                            name="idnm"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Copy of ID
                          </label>
                          <input
                            type="file"
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                0
                              )
                            }
                            name="file1"
                            className="p-1 h-300 mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />{" "}
                          {isFileUploaded && (
                            <CheckCircleOutlined
                              style={{
                                color: "green",
                                marginLeft: "8px",
                              }}
                            />
                          )}
                          {fileinputError && (
                            <div
                              style={{
                                color: "red",
                              }}
                            >
                              {fileinputError}
                            </div>
                          )}{" "}
                        </div>

                        <div className="mt-7 col-span-6 sm:col-span-3">
                          <label
                            htmlFor="LastName"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Proof of residence
                          </label>
                          <input
                            type="file"
                            onChange={(event) =>
                              handleFileChange2(
                                event,
                                0
                              )
                            }
                            name="file2"
                            className="p-1 h-300 mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                          {isFileUploaded2 && (
                            <CheckCircleOutlined
                              style={{
                                color: "green",
                                marginLeft: "8px",
                              }}
                            />
                          )}
                          {fileinputError2 && (
                            <div
                              style={{
                                color: "red",
                              }}
                            >
                              {fileinputError2}
                            </div>
                          )}{" "}
                        </div>

                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="Physicaladdress"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                          >
                            Physical address
                          </label>

                          <textarea
                            type="text"
                            value={
                              personsFields.paddy
                            }
                            onChange={
                              handlePersonChange
                            }
                            name="paddy"
                            className="p-1 h-full mt-1 w-full rounded-md text-sm text-gray-700 border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                            required
                          />
                        </div>
                        <div className="mt-7 col-span-6 sm:flex sm:items-center sm:gap-4">
                          <button
                            onClick={downloadPDF}
                            style={{
                              color: "blue",
                            }}
                          >
                            {" "}
                            <u>
                              Download{" "}
                              {businesstype}{" "}
                              Agreement/s
                            </u>
                          </button>
                        </div>
                        <div className="mt-0 col-span-6 sm:flex sm:items-center sm:gap-4">
                          <button
                            type="submit"
                            className="inline-block shrink-0 rounded-md border border-green-600 bg-green-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-green-600 focus:outline-none focus:ring active:text-green-500 dark:hover:bg-green-700 dark:hover:text-white"
                          >
                            Submit
                          </button>{" "}
                        </div>
                      </form>
                    </motion.div>
                  </div>
                )}
              </div>
            </motion.div>
          </div>
        </main>
      </div>
    </section>
  );
};

export default Signin;
