import React, {
  useState,
  useEffect,
} from "react";
import styled from "styled-components";
import logo from "../../Assets/images/Omari_White_logo 2.png";
import { useNavigate } from "react-router-dom";
import { Spin, Modal } from "antd";
import {
  getPEmail,
  getPFn,
  saveNOBEmail,
  savePEmail,
} from "../../Apis/Store";
import {
  getall,
  submitD,
} from "../../Apis/GetInfo";
import JSZip from "jszip";

// Styled components
const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #53bf4c;
  color: #fff;
  padding: 1rem 4rem;
  max-width: 100%;

  @media (max-width: 767px) {
    padding: 1rem 2rem;
  }

  @media (max-width: 575px) {
    padding: 1rem 2.5rem;
  }
`;

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  );
  grid-gap: 2rem;
  padding: 2rem;

  @media (min-width: 769px) {
    padding: 8rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 2rem;
  }

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0;
    margin: 1rem 0.5rem;
  }
`;

const ChevronIcon = styled.svg`
  width: 18px;
  height: 18px;
  fill: #fff;
  margin-left: 8px;
  transform: ${(props) =>
    props.isOpen
      ? "rotate(180deg)"
      : "rotate(0deg)"};
  transition: transform 0.3s ease;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;

  img {
    max-width: 100px;
    height: auto;

    @media (max-width: 767px) {
      max-width: 100px;
    }

    @media (max-width: 575px) {
      max-width: 80px;
    }
  }
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
  height: 300px;
`;

const FormContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CenteredText = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: #53bf4c;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
`;

const UserMenu = styled.div`
  position: relative;
  display: inline-block;
`;

const UserButton = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    justify-content: flex-end;
  }
`;

const UserDropdown = styled.div`
  display: ${(props) =>
    props.isOpen ? "block" : "none"};
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
`;

const DropdownLink = styled.a`
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

const Footer = styled.footer`
  background-color: #53bf4c;
  color: #fff;
  padding: 1.5rem;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

// Component
const Dashboard = () => {
  const navigate = useNavigate();

  // Check if the user is logged in
  useEffect(() => {
    getalldata();
    const authToken =
      localStorage.getItem("authToken");
    if (!authToken) {
      navigate("/");
    }

    const pemail = getPEmail();
    if (!pemail) {
      navigate("/");
    }
  }, [navigate]);

  const [name, setName] = useState(getPFn());
  const [pemail, setPemail] =
    useState(getPEmail());
  const [orgn, setOrgn] = useState("");
  const [emailneed, setEmailneed] = useState("");
  const [loading, setLoading] = useState(false);
  const [orgt, setOrgt] = useState("");
  const [orgtype, setOrgtype] = useState("");
  const [orgregion, setOrgregion] = useState("");
  const [orgemail, setOrgemail] = useState("");
  const [isModalVisible, setIsModalVisible] =
    useState(false);
  const [inputError, setInputError] =
    useState(false);
  const [agrees, setAgrees] = useState(false);

  const [files, setFiles] = useState([]);
  const [isMenuOpen, setIsMenuOpen] =
    useState(false);
  const [info, setInfo] = useState("");
  const [decision, setDecision] = useState("");
  const [busiType, setbusiType] = useState(false);

  const getalldata = async () => {
    setLoading(true);
    try {
      const response = await getall();
      // console.log(response);
      if (response.status == 200) {
        setOrgn(response.data.Borgn);
        setPemail(response.data.Bemail);
        setName(response.data.pfn);
        setOrgt(response.data.Borgt);
        setOrgemail(response.data.Borge);
        setOrgregion(response.data.Borgreg);
        setOrgtype(response.data.Borgtype);
        setDecision(response.data.approval);
        setInfo(response.data.info);
        setbusiType(response.data.BType);

        if (
          response.data.ag1 == 0 &&
          response.data.ag2 == 0
        ) {
          setAgrees(0);
        } else {
          setAgrees(1);
        }
        setLoading(false);
      }

      if (response.status == 500) {
        showModal500();
      }

      if (response.status == 404) {
        setName(response.pfn);
        setOrgn(0);
        setEmailneed(response.pemail);
        setLoading(false);
      }

      if (
        response.status === 400 ||
        response.status === 500
      ) {
        setLoading(false);
      }

      if (response.status === 401) {
        localStorage.removeItem("authToken");
        navigate("/");
      }

      if (response.status === 403) {
        localStorage.removeItem("authToken");
        navigate("/");
      }

      if (response.status === 429) {
        localStorage.removeItem("authToken");
        navigate("/");
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const createBusiness = () => {
    saveNOBEmail(emailneed);
    savePEmail(emailneed);
    navigate("/");
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleFileChange = (event) => {
    // Filter out files that are not PDF, PNG, JPEG, or JPG
    const acceptedFiles = Array.from(
      event.target.files
    ).filter((file) => {
      const validTypes = [
        "application/pdf", // PDF
        "image/png", // PNG
        "image/jpeg", // JPEG and JPG
      ];
      return validTypes.includes(file.type);
    });

    setFiles(acceptedFiles);
  };

  const handleBankingDetails = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`file${i + 1}`, files[i]);
    }

    try {
      const response = await submitD(formData);
      if (
        response.status === 400 ||
        response.status === 401 ||
        response.status === 500
      ) {
        setInputError(response.error);
        setLoading(false);
      } else if (response.status === 200) {
        setInputError("");
        setFiles("");
        setIsModalVisible(true);
        setLoading(false);
        getalldata();
      } else {
        setLoading(false);
        setInputError(
          "Failing to connect ,Please try again later"
        );
      }
    } catch (error) {
      console.error(
        "Error uploading files:",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const downloadPDF = (event) => {
    event.preventDefault();
    let fileUrl = "";
    if (busiType === "Merchant") {
      fileUrl =
        process.env.PUBLIC_URL +
        "/MerchantAgreement1.pdf";

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileUrl;
      a.download = "MerchantAgreement1.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // window.location.reload();
    }

    if (busiType === "Agent") {
      fileUrl =
        process.env.PUBLIC_URL +
        "/AgentForms.pdf";

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileUrl;
      a.download = "AgentForms.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // window.location.reload();
    }

    if (busiType === "Bulk Payer") {
      const fileUrls = [
        {
          url:
            process.env.PUBLIC_URL +
            "/Bulk Payer Application.pdf",
          name: "Bulk Payer Application.pdf",
        },
        {
          url:
            process.env.PUBLIC_URL +
            "/payment agreement.pdf",
          name: "payment agreement.pdf",
        },
      ];

      const zip = new JSZip();

      const fetchFile = async (file) => {
        const response = await fetch(file.url);
        const blob = await response.blob();
        zip.file(file.name, blob);
      };

      const downloadZip = async () => {
        await Promise.all(
          fileUrls.map(fetchFile)
        );
        const content = await zip.generateAsync({
          type: "blob",
        });
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = URL.createObjectURL(content);
        a.download = "Bulk payer agreements.zip";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // window.location.reload();
      };

      downloadZip();
    }
  };

  const renderDecisionContent = () => {
    if (decision === "declined") {
      return (
        <CenteredText>
          <h2
            style={{
              fontSize: "20px",
              color: "red",
            }}
          >
            Application Declined
          </h2>
          <p>
            <b>Reason for decline</b>
            <br /> {info}
          </p>
        </CenteredText>
      );
    } else if (decision === "approved") {
      return (
        <CenteredText>
          <h1
            style={{
              fontSize: "20px",
              color: "#53bf4c",
            }}
          >
            Application Approved
          </h1>
          <p>{info}</p>
        </CenteredText>
      );
    } else if (decision === null) {
      if (agrees === 1) {
        return (
          <CenteredText blurred={agrees}>
            <h2>
              Application is pending approval..
            </h2>
          </CenteredText>
        );
      } else {
        return (
          <>
            <form onSubmit={handleBankingDetails}>
              <FormContainer blurred={agrees}>
                <div className="mt-7 col-span-6 sm:flex sm:items-center sm:gap-4">
                  If you didn&apos;t already -
                  <button
                    onClick={downloadPDF}
                    style={{
                      color: "blue",
                    }}
                  >
                    <u>
                      Download {busiType}{" "}
                      Agreement/s
                    </u>
                  </button>
                </div>
                <hr />
                <h2>
                  <b>
                    Upload Filled Agreements /
                    Applications
                  </b>
                </h2>
                {inputError && (
                  <p style={{ color: "red" }}>
                    {inputError}
                  </p>
                )}
                <Input
                  type="file"
                  placeholder="Enter your name"
                  onChange={handleFileChange}
                  multiple
                  required
                />
                {files.length > 0 && (
                  <div>
                    <h3>
                      <b>Selected Files:</b>
                    </h3>
                    <ul>
                      {files.map((file) => (
                        <li key={file.name}>
                          {file.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <Button type="submit">
                  Submit
                </Button>
              </FormContainer>
            </form>
          </>
        );
      }
    }
    return null; // Default return if no conditions match
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const [
    isModalVisible500,
    setIsModalVisible500,
  ] = useState(false);

  const showModal500 = () => {
    setIsModalVisible500(true);
  };

  const handleOk500 = () => {
    setIsModalVisible500(false);
  };

  const handleCancel500 = () => {
    setIsModalVisible500(false);
  };

  return (
    <div>
      <Modal
        title="Server Error"
        visible={isModalVisible500}
        onOk={handleOk500}
        onCancel={handleCancel500}
      >
        <p>
          Failed to connect, Please try again
          later.
        </p>
      </Modal>
      {loading && (
        <Spin size="large" fullscreen />
      )}
      <Modal
        title="Success"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        centered
        okText="OK"
        cancelButtonProps={{
          style: { display: "none" },
        }}
      >
        <p>
          Agreements Successfully submitted for
          processing
        </p>
      </Modal>
      <Navbar>
        <Logo>
          <img src={logo} alt="logo" />
        </Logo>
        <UserMenu>
          <UserButton onClick={toggleMenu}>
            <span>
              Welcome,
              {name !== "undefined" ? (
                <span> {name}</span>
              ) : null}
            </span>
            <ChevronIcon
              isOpen={isMenuOpen}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 10l5 5 5-5z" />
            </ChevronIcon>
          </UserButton>
          <UserDropdown isOpen={isMenuOpen}>
            <DropdownLink onClick={handleLogout}>
              Logout
            </DropdownLink>
          </UserDropdown>
        </UserMenu>
      </Navbar>
      <Container>
        <Card>
          <h1>{busiType} Business Information</h1>
          <br />
          {orgn == 0 ? (
            <div>
              <p>No business found :</p>
              <br />
              <Button onClick={createBusiness}>
                Create Business Profile{" "}
              </Button>
            </div>
          ) : (
            <>
              <hr />
              {orgn !== "undefined" &&
              orgt !== "undefined" ? (
                <p>
                  <b>Org :</b> {orgn}
                  <br />
                  <b>Org Type :</b> {orgtype}
                  <br />
                  <b>Org Email :</b> {orgemail}
                  <br />
                  <b>Org Region :</b> {orgregion}
                </p>
              ) : null}
              <br />
              <hr />
              {pemail !== "undefined" ? (
                <span>
                  <b>Personal Email</b> : {pemail}
                </span>
              ) : null}
            </>
          )}
        </Card>
        {renderDecisionContent()}

        {/* Add more cards as needed */}
      </Container>
      <Footer>
        <p>
          &copy; 2024 O&apos;mari. All rights
          reserved.
        </p>
      </Footer>
    </div>
  );
};

export default Dashboard;
