// src/App.js
import React, { useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const App = () => {
  const [msisdn, setMsisdn] = useState("");
  const [amount, setAmount] = useState("");
  const [otp, setOtp] = useState("");
  const [newreference, setNewreference] =
    useState("");
  const [showOtpForm, setShowOtpForm] =
    useState(false);
  const [response, setResponse] = useState(null);
  const merchantKey =
    "f12f6eba-3ecb-43e1-9328-4ab376f4c983"; // Replace with your actual key

  const handleAuthSubmit = async (e) => {
    e.preventDefault();
    const reference = uuidv4(); // Generate a random UUID
    setNewreference(reference);
    const body = {
      msisdn,
      reference,
      amount: Number(amount),
      currency: "USD",
      channel: "WEB",
      merchantKey,
    };

    try {
      const res = await axios.post(
        "http://localhost:5000/api/payment/auth",
        body
      );
      setResponse(res.data);

      if (res.data.message === "Auth Success") {
        setShowOtpForm(true);
      }
    } catch (error) {
      setResponse({
        error:
          error.response?.data?.message ||
          "Error occurred",
      });
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    const body = {
      msisdn,
      newreference,
      otp,
      merchantKey,
    };
    console.log(body);
    try {
      const res = await axios.post(
        "http://localhost:5000/api/payment/request",
        body
      );
      setResponse(res.data);
    } catch (error) {
      setResponse({
        error:
          error.response?.data?.message ||
          "Error occurred",
      });
    }
  };

  return (
    <div>
      <h2>
        George&rsquo;s Pharmacy
        <br />
        MSP Live test
      </h2>

      {/* Show the initial form if OTP form is not visible */}
      {!showOtpForm && (
        <form onSubmit={handleAuthSubmit}>
          <div>
            <label>
              Your phone number:
              <input
                type="text"
                value={msisdn}
                onChange={(e) =>
                  setMsisdn(e.target.value)
                }
                required
                style={{
                  border: "2px solid #28A745", // Blue border for visibility
                  padding: "8px",
                  borderRadius: "4px",
                  marginBottom: "10px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              />
            </label>
          </div>
          <div>
            <label>
              Amount:
              <input
                type="number"
                value={amount}
                onChange={(e) =>
                  setAmount(e.target.value)
                }
                required
                style={{
                  border: "2px solid #28A745", // Green border for visibility
                  padding: "8px",
                  borderRadius: "4px",
                  marginBottom: "10px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              />
            </label>
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#28A745",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
            }}
          >
            Submit
          </button>
        </form>
      )}

      {/* Show OTP form when Auth is successful */}
      {showOtpForm && (
        <form onSubmit={handleOtpSubmit}>
          <div>
            <label>
              OTP:
              <input
                type="text"
                value={otp}
                onChange={(e) =>
                  setOtp(e.target.value)
                }
                required
                style={{
                  border: "2px solid #FF5733", // Orange border for visibility
                  padding: "8px",
                  borderRadius: "4px",
                  marginBottom: "10px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              />
            </label>
          </div>
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              backgroundColor: "#28A745",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
            }}
          >
            Submit OTP
          </button>
        </form>
      )}

      {response && (
        <div>
          <h3>Response:</h3>
          {response.message === "Auth Success" &&
          response.otpReference ? (
            <div>
              <h4>
                OTP Reference:{" "}
                {response.otpReference}
              </h4>
            </div>
          ) : (
            <pre>
              {JSON.stringify(response, null, 2)}
            </pre>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
