import {
  userEP,
  busEP,
  PerEP,
  VerifyOTP,
  ResendOTP,
} from "./Constants";
import axios from "axios";

export async function signup(jsonData) {
  try {
    const response = await axios.post(
      userEP,
      jsonData
    );
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    // console.error("Error:", error.response.data);
    return {
      error: error.response.data,
      status: error.response.status,
    };
  }
}

export async function veriOTP(jsonData) {
  try {
    const response = await axios.post(
      VerifyOTP,
      jsonData
    );
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    // console.error("Error:", error.response.data);
    return {
      error: error.response.data,
      status: error.response.status,
    };
  }
}

export async function reseOTP(jsonData) {
  try {
    const response = await axios.post(
      ResendOTP,
      jsonData
    );
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    // console.error("Error:", error.response.data);
    return {
      error: error.response.data,
      status: error.response.status,
    };
  }
}

export async function businesssignup(jsonData) {
  console.log(jsonData);
  try {
    const response = await axios.post(
      busEP,
      jsonData
    );
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    // console.error("Error:", error.response.data);
    return {
      error: error.response.data,
      status: error.response.status,
    };
  }
}

export async function personsignup(jsonData) {
  try {
    const response = await axios.post(
      PerEP,
      jsonData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    // console.error("Error:", error.response.data);
    return {
      error: error.response.data,
      status: error.response.status,
    };
  }
}
