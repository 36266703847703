import {
  Getallinfo,
  SubmitDox,
} from "./Constants";
import axios from "axios";

export async function getall() {
  const authToken =
    localStorage.getItem("authToken");
  try {
    const response = await axios.post(
      Getallinfo,
      {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    // console.log(error.response);
    if (
      error.response &&
      error.response.status === 404
    ) {
      return {
        message: "Business not found",
        status: 404,
        pfn: error.response.data.pfn,
        pemail: error.response.data.pemail,
      };
    } else {
      return {
        message: "An error occurred",
        status: error.response
          ? error.response.status
          : 500,
      };
    }
  }
}

export async function submitD(jsonForm) {
  const authToken =
    localStorage.getItem("authToken");
  try {
    const response = await axios.post(
      SubmitDox,
      jsonForm,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response;
  } catch (error) {
    return error.response;
  }
}
